<template>
  <div class="row">
    <div class="col-12">
      <card :key="componentKey" >
        <div slot="header" class="col-12 d-flex flex-wrap">
          <h4 class="card-title">{{ $store.getters['languageMixin/getStringFromLanguage']('Sent Email List') }}</h4>
          <span style="margin-left: 10px;color: #3f3b3b;margin-top: 5px;" v-html="itemName"></span>
        </div>
        <div class="d-flex justify-content-end">

        </div>

        <div>
          <general-data-table
            ref="table"
            :key="componentKey"
            :api-url="'email-archives/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">

            </el-table-column>




            <el-table-column
              :min-width="120"
              align="center"
              fixed="right"
              :label="$store.getters['languageMixin/getStringFromLanguage']('Actions')">
              <template slot-scope="scope">

                <template>
                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['email-archives/view'])"
                     v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('View')"
                     class="btn-success btn-simple btn-link"
                     @click="openViewModal(scope.row.html)">
                    <i class="fa fa-eye"></i></a>

                </template>


              </template>
            </el-table-column>

          </general-data-table>
        </div>
      </card>
    </div>

    <delete-modal :visible="deleteModalVisibility"
                  :message="deleteMessage"
                  :type="deleteModalType"
                  @close="closeDeleteModal()"
                  @confirm="confirmDeleteModal()">
    </delete-modal>
    <view-email-modal :visible="viewModalVisibility"
    :message="viewMessage"
    @close="closeViewModal()"
    :html="html">

    </view-email-modal>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";
import AddRegistrationModal from "../../../components/Modals/AddRegistraionModal";
import ViewEmailModal from "../../../components/Modals/ViewEmailModal";


export default {
  components: {
    ViewEmailModal,
    AddRegistrationModal,
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    FgSelect,
  },
  data() {

    return {
      tableColumns: [
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Member'), value: 'member.email', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Member Name'), value: 'member.full_name', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Subject'), value: 'subject', minWidth: '200', align: 'center'},
      ],

      filterBuilders: {
      },
      deleteModalVisibility: false,
      viewModalVisibility: false,
      toDeleteId: undefined,
      toViewId: undefined,
      deleteMessage: '',
      viewMessage: '',
      html: '',
      componentKey: 0,
      registrationType: null,
      id: undefined,
      filters: {
        session_id: null,
        cycle_id: null
      },
      itemName: '',
      itemDays: '',
      deleteModalType: '',

    }
  },
  async mounted() {
    if (this.$route.params['type'] === 'cycle'){
      this.filters.cycle_id = this.$route.params['id'];
    }else{
      this.filters.session_id = this.$route.params['id'];
    }
    let data = {
      'cycle_id': this.filters.cycle_id,
      'session_id': this.filters.session_id,
    };
    let response = await this.axios.post("waiting-lists/builder", data);
    this.itemName = response.data.name;
    this.itemDays = response.data.days;
    this.applyFilter();
  },
  methods: {
    openDeleteModal(id, type) {
      this.deleteModalType = type;
      if (type === 'move'){
        this.deleteMessage = this.$store.getters['languageMixin/getStringFromLanguage']('Are you sure you want to move this member to email archive list?')
      }else{
        this.deleteMessage = this.$store.getters['languageMixin/getStringFromLanguage']('Are you sure you want to delete this member from email archive list?')
      }
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    openViewModal(html) {
      this.html = html;
      this.deleteMessage = this.$store.getters['languageMixin/getStringFromLanguage']('Email View')
      this.viewModalVisibility = true;
    },
    closeViewModal() {
      this.viewModalVisibility = false;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    async confirmDeleteModal() {
      try {
        let data = {
          'id': this.toDeleteId,
        }
        if (this.deleteModalType === 'move'){
          await this.axios.post("waiting-lists/move", data);
        }else{
          await this.axios.delete("waiting-lists/delete", {headers: {}, data});
        }
        this.$refs.table.getData("updateData");
        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("Waiting list updated successfully"),
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },
    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.filters.session_id || this.filters.cycle_id) {
        this.$refs.table.createDragAndDropSorting();
      } else {
        this.$refs.table.destroyDragAndDropSorting();
      }
      this.$refs.table.getData("updateData");
    },
  },
}
</script>

<style>
  .register_status{
    color: #ffff;
  }
</style>

